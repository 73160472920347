import { Box, Container, Heading, Text, useColorMode } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ImgTextModule from "../components/ImgTextModule";
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";

export default function InvestorRelations() {
	const { setColorMode } = useColorMode();

	useEffect(() => {
		setColorMode("light");
	});
	return (
		<>
			<Helmet>
				<title>tokentus | Contact</title>
			</Helmet>
			<ImgTextModule
				src="target.mp4"
				delay={0.3}
				duration={0.5}
				threshold={0.3}
				initialY={10}
				initialX={10}
				video={true}
				h="55vh"
				mediaOpacity={0.9}
				bgColor="brand.black">
				<Container variant="layoutContainer" mt={8}>
					<Box p={{ base: 0, lg: "8" }} py={8}>
						<FadeInAnimation
							threshold={0.4}
							delay={0.4}
							duration={0.7}
							initialX={0}
							initialY={-10}>
							<Text
								color="white"
								fontWeight="normal"
								fontSize="xl">
								Contact us
							</Text>
						</FadeInAnimation>
						<FadeInAnimation
							threshold={0.4}
							delay={0.4}
							duration={0.7}
							initialX={0}
							initialY={-10}>
							<Text
								color="brand.white"
								fontWeight="normal"
								fontSize={{ base: "5xl", lg: "6xl" }}>
								How to reach us.
							</Text>
						</FadeInAnimation>
					</Box>
				</Container>
			</ImgTextModule>

			<Container
				variant={"layoutContainer"}
				px={{ base: 0, lg: "8" }}
				mt={16}
				w={{ base: "100%", lg: "50%" }}
				ml={{ lg: "50%" }}>
				<Heading
					fontSize="4xl"
					fontWeight="normal"
					mb={4}
					color="brand.meddarkgray">
					tokentus investment AG
				</Heading>
				<Text
					w={{ base: "50%", lg: "50%" }}
					pt={2}
					color="brand.darkmedgray"
					fontWeight="normal"
					fontSize="lg">
					<br />
					Taunusanlage 8 c/o WeWork
					<br />
					60329 Frankfurt a. Main
					<br /> <br />
					Contact
					<br />
					<br />
					Telefon: +49 177 24 21 383
					<br />
					email: contact@tokentus.com
				</Text>
				<Box mt={20}></Box>
			</Container>
		</>
	);
}
